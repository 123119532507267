import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"

const Burger0 = styled.span(({ isOpen }) => [
  tw`bg-system-grey-600 block h-1 w-8 mb-1 ease-in-out duration-200`,
  isOpen && `transform: translateY(8px) rotate(135deg)`,
])
const Burger1 = styled.span(({ isOpen }) => [
  tw`bg-system-grey-600 block h-1 w-8 mb-1 ease-in-out duration-200`,
  isOpen && `transform: scale(0)`,
])
const Burger2 = styled.span(({ isOpen }) => [
  tw`bg-system-grey-600 block h-1 w-8 ease-in-out duration-200`,
  isOpen && `transform: translateY(-8px) rotate(-135deg)`,
])

export default function Hamburger({ isOpen }) {
  return (
    <>
      <Burger0 isOpen={isOpen} />
      <Burger1 isOpen={isOpen} />
      <Burger2 isOpen={isOpen} />
    </>
  )
}

Hamburger.propTypes = {
  isOpen: PropTypes.bool,
}
