import React, { useState, useEffect } from "react"
import Navbar from "./navbar/"
import Footer from "./footer/"
import tw, { GlobalStyles } from "twin.macro"
import "fontsource-roboto/400.css"
import "fontsource-roboto/700.css"
import "fontsource-roboto-condensed/400.css"
import "fontsource-roboto-condensed/700.css"

const GlobalWrapper = tw.div`antialiased font-sans flex flex-col h-screen`
const Main = tw.main``
const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  const [isSmall, setIsSmall] = useState(false)

  useEffect(() => {
    const handleScroll = event => {
      let scrollPosition = Math.round(window.scrollY)

      if (scrollPosition > 150) {
        if (!isSmall) {
          setIsSmall(true)
        }
      } else {
        if (isSmall) {
          setIsSmall(false)
        }
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isSmall])

  return (
    <GlobalWrapper data-is-root-path={isRootPath}>
      <GlobalStyles />
      <Navbar isSmall={isSmall} />
      <Main css={[isSmall ? tw`pt-navbar-small` : tw`pt-navbar`]}>
        {children}
      </Main>
      <Footer />
    </GlobalWrapper>
  )
}

export default Layout
