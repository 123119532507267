import React from "react"
import tw from "twin.macro"
import NavList from "./nav-list"

const FooterStyle = tw.footer`w-full py-6 text-system-grey-600 px-5 lg:px-10 mt-auto`
const FooterWrapper = tw.footer`flex md:justify-between items-center max-w-screen-xl m-auto flex-wrap justify-center text-center`
const CopyWrite = tw.div`min-w-full md:min-w-0 text-lg`
const Address = tw.div`flex text-lg flex-col min-w-full md:min-w-0`

export default function Footer() {
  return (
    <FooterStyle>
      <FooterWrapper>
        <CopyWrite>
          <span>&copy; Copyright {new Date().getFullYear()} Solvi AB</span>
        </CopyWrite>
        <NavList />
        <Address>
          <span>Drottninggatan 38</span>
          <span>Gothenburg, Sweden</span>
        </Address>
      </FooterWrapper>
    </FooterStyle>
  )
}

Footer.propTypes = {}
