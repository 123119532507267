import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"

import Item from "./item"

const NavElement = styled.nav(({ isOpen }) => [
  tw`overflow-hidden h-0 opacity-0 md:h-auto md:opacity-100 md:flex md:items-center ease-in duration-200`,
  isOpen && tw`h-64 opacity-100`,
])

export default function Nav({ isOpen }) {
  return (
    <NavElement id="nav-menu" isOpen={isOpen}>
      <ul tw="list-none m-0 my-4 md:my-0 p-0 uppercase font-condensed md:flex">
        <Item name="Features" href={`${process.env.APP_DOMAIN}/features`} />
        <Item name="Pricing" href={`${process.env.APP_DOMAIN}/pricing`} />
        <Item name="About" href={`${process.env.APP_DOMAIN}/about`} />
        <Item
          name="Blog"
          href={`${process.env.APP_DOMAIN}/blog`}
          active={true}
        />
      </ul>
      <ul tw="md:ml-3 list-none m-0 p-0 flex">
        <li tw="m-2  flex-shrink-0">
          <a
            tw="tracking-wider text-primary leading-6 text-center py-2 px-5 rounded uppercase duration-100 ease-linear bg-white border border-solid border-primary font-condensed font-bold hover:bg-primary-hover hover:text-white hover:no-underline focus:text-white focus:no-underline focus:text-primary"
            href={`${process.env.APP_DOMAIN}/login`}
          >
            Log in
          </a>
        </li>
        <li tw="m-2 flex-shrink-0">
          <a
            tw="tracking-wider text-white leading-6 text-center py-2 px-5 rounded uppercase duration-100 ease-linear bg-primary border border-solid border-primary font-condensed font-bold hover:bg-primary-hover hover:text-white hover:no-underline focus:text-white focus:no-underline"
            href={`${process.env.APP_DOMAIN}/signup`}
          >
            Sign up
          </a>
        </li>
      </ul>
    </NavElement>
  )
}

NavElement.propTypes = {
  isOpen: PropTypes.bool,
}
