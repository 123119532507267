import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"

const ListItem = tw.li`p-2 md:p-0 md:pr-4 lg:px-4`
const NavLink = styled.a(({ active }) => [
  tw`duration-100 pb-1 ease-linear hover:text-primary hover:border-b hover:border-primary`,
  active ? tw`text-primary` : tw`text-system-grey-500`,
])

export default function Item({ name, href, active }) {
  return (
    <ListItem>
      <NavLink active={active} href={href}>
        {name}
      </NavLink>
    </ListItem>
  )
}

Item.propTypes = {
  name: PropTypes.string,
  href: PropTypes.string,
}
