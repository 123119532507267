import React from "react"
import tw from "twin.macro"

const List = tw.ul`flex my-5 md:my-0 list-none m-0 p-0 uppercase font-condensed flex-wrap w-4/12 justify-evenly`
const NavLink = tw.a`text-system-grey-600 cursor-pointer px-4 lg:px-1 py-4 duration-100 ease-linear hover:text-primary`

export default function NavList() {
  const links = [
    { Features: `${process.env.APP_DOMAIN}/features` },
    { Pricing: `${process.env.APP_DOMAIN}/pricing` },
    { About: `${process.env.APP_DOMAIN}/about` },
    { Terms: `${process.env.APP_DOMAIN}/terms` },
    { Privacy: `${process.env.APP_DOMAIN}/privacy` },
    { Blog: `${process.env.APP_DOMAIN}/blog` },
  ]
  return (
    <List>
      {links.map(link => (
        <ListLinkItem
          key={Object.keys(link)[0]}
          href={Object.values(link)[0]}
          title={Object.keys(link)[0]}
        />
      ))}
    </List>
  )
}

function ListLinkItem({ title, href }) {
  return (
    <li tw="block py-2">
      <NavLink href={href}>{title}</NavLink>
    </li>
  )
}
