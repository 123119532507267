import React, { useState } from "react"
import tw from "twin.macro"
import Hamburger from "./hamburger"
import Nav from "./nav"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Header = tw.header`shadow-navbar mb-1 fixed w-full z-10 bg-white transition-all duration-500 ease`
const Button = tw.button`p-2 md:hidden`
const HomeLink = tw.a`leading-none`

export default function Navbar({ isSmall }) {
  const [isOpen, setIsOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query Logo {
      logo: file(absolutePath: { regex: "/solvi-logo.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 108, quality: 100, layout: FIXED)
        }
      }
    }
  `)

  const logo = data?.logo?.childImageSharp?.gatsbyImageData

  return (
    <Header css={[isSmall ? tw`py-2` : tw`py-5`]}>
      <div tw="md:flex md:justify-between max-w-screen-xl m-auto text-base px-5 lg:px-10 xl:px-5">
        <div tw="flex justify-between items-center">
          <HomeLink
            alt="home"
            href={`${process.env.APP_DOMAIN}`}
            aria-label="home"
          >
            <GatsbyImage
              alt="Solvi logo"
              image={logo}
              css={[
                tw`transition duration-500 ease `,
                isSmall && tw`transform scale-75`,
              ]}
            />
          </HomeLink>
          <Button onClick={() => setIsOpen(!isOpen)}>
            <Hamburger isOpen={isOpen} />
          </Button>
        </div>
        <Nav isOpen={isOpen}></Nav>
      </div>
    </Header>
  )
}
